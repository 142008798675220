import { Router } from '@reach/router';
import React, { Suspense } from 'react';

import Home from './pages/home';
import './App.css';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router style={{ height: '100%' }}>
        <Home path="/:projectId" />
      </Router>
    </Suspense>
  );
}

export default App;
